body {
  overflow-x: hidden;
}

.movie-list-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.media-link {
  color: inherit;
  text-decoration: none;
  padding: 10px;
  margin: 5px;
  font-size: 1rem;
}

.media-link:hover {
  font-weight: bold;
  transform: scale(1.05);
}

@media (max-width: 1000px) {
.movie-list-container {
  display: grid;
    max-width: 100%;
    margin: 0 auto;
  }
}

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.homepage-link {
  padding: 10px 20px;
  margin: 5px 2px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.homepage-content {
  text-align: center;
}

.homepage-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.homepage-description {
  font-size: 1.2em;
  color: #555;
}

.navbar {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background-color: #fafafa;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.movie-search-input {
  width: calc(100% - 80px);
  height: 30px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.media-type-button {
  padding: 10px 20px;
  margin: 5px 2px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.movie-search-button {
  padding: 10px 20px;
  margin: 5px 2px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tv-search-button {
  padding: 10px 20px;
  margin: 5px 2px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.load-more-button {
  position: fixed;
  bottom: 2%;
  left: 2%;
  height: 45px;
  width: 150px;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .load-more-button {
    width: 120px;
    height: 40px;
    font-size: 14px;
    right: 30px;
    left: auto;
  }
}

.movie-search-button:hover {
  background-color: #0056b3;
}

.tv-search-button:hover {
  background-color: #0056b3;
}

.movie-search-button.selected {
  background-color: #004c9c;
}

.tv-search-button.selected {
  background-color: #004c9c;
}

.tv-search-button.selected {
  background-color: #004c9c;
}

.movie-list {
  display: flex;
  flex-wrap: wrap;
}

.movie-card {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 250px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.movie-card:hover {
  background-color: #f5f5f5;
  transform: scale(1.02);
}

.movie-card img {
  width: 100%;
  border-radius: 8px;
}

.movie-details {
  padding: 10px 0;
}

.nav-bar {
  display: flex;
}

.nav-item {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.nav-item:hover {
  background-color: #f0f0f0;
}

.active {
  background-color: #007BFF;
  color: white;
  border: none;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 75%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 95%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.close {
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
.modal {
    width: 80%;
  }
}

.modal-content {
  background-color: white;
  width: 100%;
}

.close {
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #aaa;
}

.poster-container {
  text-align: center;
}

.poster-container img {
  max-width: 20%;
  max-height: 30%;
  height: auto;
}
